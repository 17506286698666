<template>
  <div class="p-6 max-w-md w-full bg-white shadow-md rounded-md">
    <h2 class="text-xl leading-9 font-extrabold text-gray-900">
      Forgot password
    </h2>
    <div class="mt-4 bg-blue-600 text-white rounded-md px-4 py-2" v-if="ok">
      We have sent an email to the specified address with additional
      instructions to complete your password reset.
    </div>
    <div class="h-full mt-4 flex flex-col space-y-10 text-left">
      <form v-on:submit.prevent="requestReset">
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-5 text-gray-900"
          >
            Email address
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="email"
              type="email"
              required
              autocomplete="email"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              v-model="email"
            />
          </div>
        </div>

        <div class="mt-6">
          <span class="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              class="bg-blue-600 text-white text-center w-full px-4 py-2 rounded-lg transition duration-150 ease-in-out focus:outline-none"
            >
              <span v-if="!performing">Email Reset Instructions</span>
              <span v-if="performing"
                ><i class="fa fa-circle-notch fa-spin"></i
              ></span>
            </button>
          </span>
        </div>

        <div class="mt-6 flex items-center justify-center">
          <div class="text-sm leading-5 ">
            <router-link
              :to="{ name: 'AuthLogin' }"
              class="font-medium text-gray-500 hover:text-gray-800 focus:outline-none focus:underline transition ease-in-out duration-150"
              >Return to login</router-link
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AuthForgotPassword",
  data() {
    return {
      performing: false,
      email: null,

      ok: false,
    };
  },
  methods: {
    ...mapActions("auth", ["performLogin"]),
    async requestReset() {
      this.performing = true;
      await this.$http.get(
        process.env.VUE_APP_API_URL + "/sanctum/csrf-cookie"
      );
      let res = await this.$http.post(
        process.env.VUE_APP_API_URL + "/forgot-password",
        {
          email: this.email,
        }
      );
      if (res.status == 200) {
        this.ok = true;
      }
      this.performing = false;
    },
  },
};
</script>
